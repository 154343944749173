/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'


Vue.use(VueRouter)
const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/agendamentos',
    name: 'Agendamentos',
    component: () => import('../views/Agendamentos.vue')
  },
  {
    path: '/contentores',
    name: 'Contentores',
    component: () => import('../views/Contentores.vue')
  },
  {
    path: '/contentor',
    name: 'Contentor',
    component: () => import('../views/Contentor.vue')
  },
  {
    path: '/contentor/:id',
    name: 'ContentorCodigo',
    component: () => import('../views/Contentor.vue')
  },
  {
    path: '/parada',
    name: 'Parada',
    component: () => import('../views/Report.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router