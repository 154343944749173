import * as moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');

export default {
  methods: {
    dateToHHMM(date) {
      if (!date) return null;
      return moment(String(date)).format('HH:mm');
    },
    parseDate(date) {
      if (!date) return null;
      return moment(String(date)).format('YYYY-MM-DD');
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
  }
}