
<template>
  <v-app dense>
    <v-app-bar app dense :color="corBarra" style="z-index:999">
      <div class="d-flex align-center"></div>
      <div class="pt-1">
        <v-badge inline :color="connected?'green':'red'"></v-badge>
      </div>
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :fab= "showAgendamentos ? true : '' "
        :plain = "showAgendamentos ? false : true "
        small
        :dark = "showAgendamentos ? true : false "
        :color= "showAgendamentos ? 'green' : ''"
        @click="showAgendamentos = !showAgendamentos"
        v-if="$route.path != '/'"
      >
        <v-icon>mdi-calendar-check-outline</v-icon>
      </v-btn>
      <v-btn
        color="black darken-2"
        icon
        @click.stop="abastecimento = true;setKmsIniciais()"
        v-if="$route.path != '/'"
      >
        <v-icon>mdi-gas-station</v-icon>
      </v-btn>
      <v-btn
        color="blue darken-2"
        icon
        @click.stop="tratamento = true;setKmsIniciais()"
        v-if="$route.path != '/'"
      >
        <v-icon>mdi-arrow-expand-up</v-icon>
      </v-btn>
      <v-btn
        color="green darken-2"
        icon
        @click.stop="checkIfAllDone();setKmsIniciais()"
        v-if="$route.path != '/'"
      >
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view
        @showSnackbar="handleShowSnackbar">
      </router-view>
    </v-main>

    <v-dialog v-model="sair" max-width="90vw">
      <v-card class="px-2">
        <v-card-title class="headline">Finalizar sessão</v-card-title>
        <v-text-field
          label="Quilometragem Final"
          v-model="km.km"
          name="km"
          type="number"
          :rules="rules"
        ></v-text-field>
        <v-card-text></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            class="ma-2 white--text"
            @click="logout()"
            :disabled="km.km < kmsIniciais">
            Finalizar
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
          <v-btn
            color="error"
            class="ma-2 white--text"
            text
            @click="
              sair = false;
              km.km = 0;
            "
          >
            Cancelar
            <v-icon right dark>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="abastecimento" max-width="90vw">
      <v-card class="px-2">
        <v-card-title class="headline">Abastecimento</v-card-title>
        <v-text-field
          label="Quilometragem"
          v-model="km.km"
          name="km"
          type="number"
          :rules="rules"
        ></v-text-field>
        <v-text-field
          label="Quantidade(L)"
          v-model="km.quantidade"
          name="quantidade"
          type="number"
        ></v-text-field>
        <v-card-text></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            class="ma-2 white--text"
            @click="abastece()"
            :disabled="km.km < kmsIniciais"
          >
            Salvar
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
          <v-btn
            color="error"
            class="ma-2 white--text"
            text
            @click="
              abastecimento = false;
              km.km = 0;
              km.quantidade = 0;
            "
          >
            Cancelar
            <v-icon right dark>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="tratamento" max-width="90vw">
      <v-card class="px-2">
        <v-card-title class="headline">Descarga</v-card-title>
        <v-text-field
          label="Quilometragem"
          v-model="km.km"
          name="km"
          type="number"
          :rules="rules"
        ></v-text-field>
        <v-text-field
          label="Quantidade(KG)"
          v-model="km.quantidade"
          name="quantidade"
          type="number"
        ></v-text-field>
        <v-card-text></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="progress"
          ></v-progress-circular>
          <v-btn
            color="green"
            class="ma-2 white--text"
            @click="descarga()"
            :disabled="(km.km < kmsIniciais || km.quantidade <= 0) || progress"
          >
            Salvar
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
          <v-btn
            color="error"
            class="ma-2 white--text"
            text
            @click="
              tratamento = false;
              km.km = 0;
              km.quantidade = 0;
            "
          >
            Cancelar
            <v-icon right dark>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar bottom :value="updateExists" :timeout="-1" color="primary">
      Atualização disponível
      <v-btn text @click="refreshApp">
        Atualizar
      </v-btn>
    </v-snackbar>

    <v-snackbar bottom v-model="snackbar" timeout="2500" color="primary">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-app>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import update from './plugins/update';
import mixin from "./plugins/mixin";

export const contentores = [];

export default Vue.extend({
  name: "App",
  mixins: [update, mixin],
  components: {

  },
  data: () => ({
    API_URL: process.env.VUE_APP_API_URL,
    progress: false,
    titulo: "SmartWaste Mobile",
    sair: false,
    timer: "",
    corBarra: "",
    gps: {},
    km: { km: "", quantidade: 0, tipo: "", caminhao: null, user: null },
    abastecimento: false,
    tratamento: false,
    showAgendamentos: false,
    snackbar: false,
    snackbarMessage: '',
    kmsIniciais: "",
    //
  }),
  watch: {
    showAgendamentos: function() {
      if (this.showAgendamentos) {
        this.$router.push("/agendamentos");
      }
      else {
        this.$router.push("/contentores");
      }
    }
  },
  computed: {
    connected() {
      return this.$store.getters['connected']
    },
    rules: function() {
      let kms = this.getObjeto("kmsInit");
      const rules = [
        (v) => !!v || "Preenchimento obrigatório",
        (v) => (v && v >= kms) || `Kilómetros devem ser superiores a ${kms}`
      ];
      return rules
    },
  },
  created() {
    this.$getLocation().then((coordinates) => {
      //console.log(coordinates);
    });

    this.timer = setInterval(this.getGPS, 1000 * 10);
    // this.timer = setInterval(this.sendGPS, 1000 * 60);
    setInterval(this.setReportContentorOFF, 1000 * 35);

    //console.log(this.getObjeto("GPS").lat);
  },
  methods: {
    checkIfAllDone() {
      let allDone = true;
      let count = 0;
      if (this.getObjeto("rotas") && this.getObjeto("rotaSelecionada")) {
        let contentores = this.getObjeto("rotas").find((rota) => {return rota.id == this.getObjeto("rotaSelecionada")}).Contentors;
        contentores.every((item) => {
          if (!item.contentor.report) {
            allDone = false;
            return false; //similar to break
          }
          count++;
          return true; //similar to continue
          //this "return true;" must be here for the "every" to continue
        });
      }
      if (allDone || count == 0) {
        this.sair = true;
      } else {
        this.handleShowSnackbar("Todos os contentores devem estar concluidos");
      }
      //we can only end session when all completed
      //or when none completed (ex:started by mistake)
    },
    handleShowSnackbar(message) {
      this.snackbarMessage = message;
      this.snackbar = true;
    },
    logout() {
      this.showAgendamentos = false; //Ugly way to fix a bug but prevents changing 3 other vues for a pretty fix
      this.km.tipo = "final";
      this.km.user = this.getObjeto("user");
      this.km.caminhao = this.getObjeto("caminhao");
      this.km.data_registro = new Date();
      this.setKm(this.km).then((result) => {
        this.km.km = "";
        this.km.quantidade = 0;
        this.sair = false;

        // localStorage.removeItem("user");
        // localStorage.removeItem("token");
        // localStorage.removeItem("rotas");
        // localStorage.removeItem("caminhao");
        // localStorage.removeItem("rotaSelecionada");
        // localStorage.removeItem("historicocircuitos");

        localStorage.clear();
        this.$router.push("/");
      });
    },
    descarga() {
      this.km.tipo = "descarga";
      this.km.user = this.getObjeto("user");
      this.km.caminhao = this.getObjeto("caminhao");
      this.km.data_registro = new Date();

      this.setProgress(true);
      this.tratamento = false;

      this.setKm(this.km).then((result) => {
        this.setProgress(false);
        this.km.km = "";
        this.km.tipo = "";
        this.km.quantidade = 0;
        this.tratamento = false;
      });
    },
    setProgress(progress) {
      this.progress = progress;
    },
    abastece() {
      this.km.tipo = "combustível";
      this.km.user = this.getObjeto("user");
      this.km.caminhao = this.getObjeto("caminhao");
      this.km.data_registro = new Date();

      this.setProgress(true);
      this.abastecimento = false;

      this.setKm(this.km).then((result) => {
        this.setProgress(false);
        this.km.km = 0;
        this.km.tipo = "";
        this.km.quantidade = 0;
        this.abastecimento = false;
      });
    },
    setKm(km) {
      this.km.historicocircuito = this.getObjeto("historicocircuitos");
      return this.$http.post("kms/", this.km, {
        headers: {
          Authorization: `Bearer ${this.getObjeto("token")}`,
        },
      });
    },
    setKmsIniciais() {
      this.kmsIniciais = this.getObjeto("kmsInit");
    },
    getGPS() {
      this.$getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0, //defaults to 0
      })
        .then((data) => {
          if (
            (this.getObjeto("rotaSelecionada") || this.getObjeto("freguesiaSelecionada")) &&
            this.getObjeto("caminhao") &&
            this.getObjeto("user").id &&
            data.lat != this.gps.lat &&
            data.lng != this.gps.lng
          ) {
            this.gps = data;
            this.setObjeto("GPS", data);
            data.datahora_registrado = new Date();
            data.funcionario = this.getObjeto("user").id;
            data.rota = this.getObjeto("rotaSelecionada");
            data.freguesia = this.getObjeto("freguesiaSelecionada");
            data.caminhao = this.getObjeto("caminhao");
            if (data.caminhao) {
              data.caminhao.lat = data.lat;
              data.caminhao.lng = data.lng;
              data.caminhao.data_registro = data.datahora_registrado;
            }
            /*
            this.$http
              .post("loggps/", data, {
                headers: {
                  Authorization: `Bearer ${this.getObjeto("token")}`,
                },
              })
              .then((response) => {})
              .catch((error) => {
                // Handle error.
                console.log("An error occurred:", error);
              }); */
          }
        })
        .catch((error) => {
          // Handle error.
          console.log("An error occurred:", error);
        });
    },
    updated() {
      clearInterval(this.timer);
    },
    setReportContentorOFF() {
      var fn = this;

      if (navigator.onLine) {
        var reports = this.getObjeto("reportsOff")
          ? this.getObjeto("reportsOff")
          : [];
        var reportsOffNew = [];
        this.setObjeto("reportsOff", reportsOffNew);
        for (let i = 0; i < reports.length; i++) {
          this.uploadFotos(reports[i]).then((report) => {
            var retorno = false;
            if (report.id) {
              retorno = this.putReport(report);
            } else {
              retorno = this.postReport(report);
            }
          });
        }
      }
    },

    uploadFotos(report) {
      var count = 1;
      var fn = this;
      return new Promise((resolve) => {
        const tamanho = report.fotosOff.length;
        if (report.fotosOff.length > 0) {
          for (let i = 0; i < report.fotosOff.length; i++) {
            let formData = new FormData();
            formData.append(
              "files",
              fn.b64toBlob(report.fotosOff[i]),
              Date.now() + ".jpg"
            );
            fn.$http
              .post(this.API_URL+"/upload/", formData, {
                headers: {
                  Authorization: `Bearer ${fn.getObjeto("token")}`,
                },
              })
              .then(function (response) {
                report.fotos.push(response.data[0]);
                report.fotosOff.splice(i, 1);
                if (count == tamanho)
                  resolve(report);
                else
                  count++;
              })
              .catch((error) => {
                if (count == tamanho) resolve(report);
                else count++;
                console.log("An error occurred:", error);
              });
          }
        } else {
          resolve(report);
        }
      });
    },
    postReport(report) {
      var objeto = report;
      objeto.fotosOff = [];
      this.$http
        .post("reports", objeto, {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          report.id = response.data.id;
          this.guardar(report);
          return true;
        })
        .catch((error) => {
          return false;
          console.log("An error occurred:", error);
        });
    },
    putReport(report) {
      var objeto = report;
      objeto.fotosOff = [];
      this.$http
        .put("reports/" + report.id, objeto, {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.$router.push("/contentores");
          return true;
        })
        .catch((error) => {
          return false;
          // Handle error.
          console.log("An error occurred:", error);
        });
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    },
  },
});
</script>
